import { Component, Input } from '@angular/core';
import { EventState } from "../../site/model/state-api";
import { AlarmLevelEntry } from "../../site/model/site-api";
import { Router } from "@angular/router";

@Component({
    selector: 'app-search-result-entry',
    templateUrl: './search-result-entry.component.html',
    styleUrls: ['./search-result-entry.component.scss'],
    standalone: false
})
export class SearchResultEntryComponent {
  @Input() name: string | undefined
  @Input() link: string | undefined
  @Input() eventState: EventState | undefined
  @Input() alarmState: AlarmLevelEntry[] = []

  constructor(private router: Router) {
  }

  navigate() {
    if (!this.link) return
    this.router.navigate([this.link]).then()
  }
}

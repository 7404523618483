import { Component, Input } from '@angular/core';
import { SearchResultEntry } from "../model/search-api";
import { ResourceHierarchyNode } from "../../hierarchy/model/hierarchy-api";
import { EventState } from "../../site/model/state-api";
import { AlarmLevelEntry } from "../../site/model/site-api";
import { System } from "../../system/model/system-api";
import { SystemService } from "../../system/model/system.service";

@Component({
    selector: 'app-search-result-entry-system',
    templateUrl: './search-result-entry-system.component.html',
    styleUrls: ['./search-result-entry-system.component.scss'],
    standalone: false
})
export class SearchResultEntrySystemComponent {

  entry: SearchResultEntry | undefined
  hierarchy: ResourceHierarchyNode | undefined
  eventState: EventState | undefined
  alarmState: AlarmLevelEntry[] = []
  image: string = ''
  system: System | undefined

  @Input('data')
  set data(data: SearchResultEntry) {
    this.entry = data
    this.updateValues()
  }

  constructor(private service: SystemService) {
  }

  private updateValues() {
    let e = this.entry;
    if (!e) {
      this.clear()
    } else {
      this.update(e)
    }
  }

  private clear() {
    this.hierarchy = undefined
    this.eventState = undefined
    this.alarmState = []
    this.system = undefined
  }

  private update(e: SearchResultEntry) {
    this.service.getPath(e.resourceId).subscribe(d => {
      this.hierarchy = d
    })
    this.service.getInfo(e.resourceId).subscribe(d => {
      this.image = this.service.getSystemImage(d.system.type)
      this.system = d.system
      this.eventState = d.eventState
      this.alarmState = d.alarmState
    })
  }

  getRouterLink(): string {
    let e = this.entry;
    if (!e) {
      return ''
    } else {
      return '/system/' + e.resourceId
    }
  }
}

import { Component } from '@angular/core';
import { SearchService } from "../model/search.service";
import { FilterService } from "../../filter/model/filter.service";
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from "@angular/router";
import { filter, Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatChipListboxChange } from "@angular/material/chips";

@Component({
    selector: 'app-search-action',
    templateUrl: './search-action.component.html',
    styleUrls: ['./search-action.component.scss'],
    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
    ],
    standalone: false
})
export class SearchActionComponent {

  private searchChangeSubject: Subject<string> = new Subject()
  filter: UntypedFormGroup
  selectedTypes = new FormControl()
  filterPanelVisible = false
  private subscription: Subscription | null = null

  constructor(
    private fb: UntypedFormBuilder,
    public service: SearchService,
    public filterService: FilterService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.filter = fb.group(
      {
        refrigerationFilter: fb.control('', []),
        systemTypeFilter: fb.control('', []),
        ownerFilter: fb.control('', []),
      }
    )
    this.selectedTypes.setValue(this.service.availableTypes)
  }

  ngOnInit(): void {
    this.searchChangeSubject.pipe(
      filter(Boolean),
      debounceTime(500),
    ).subscribe(text => this.search(text))

    this.route
      .queryParamMap
      .subscribe(value => this.handleParamsChange(value))

    this.subscription = this.filterService.activeFilter.subscribe(_ => this.applyFilter())
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }


  onSearchChange(value: string) {
    console.log("Handle search change '" + value + "'")
    this.searchChangeSubject.next(value)
  }

  search(query: string) {
    let typeFilter = this.selectedTypes.value ?? []
    let refrigerationFilter = this.filter.get('refrigerationFilter')?.value ?? ""
    let systemTypeFilter = this.filter.get('systemTypeFilter')?.value ?? ""
    let ownerFilter = this.filter.get('ownerFilter')?.value ?? ""
    let online = this.filterService.activeFilter.value.onlineFilter
    let alarm = this.filterService.activeFilter.value.alarmFilter
    let alarmRange = this.filterService.activeFilter.value.lastAlarms

    const navigationExtras: NavigationExtras = {
      queryParams: {
        query: query,
        refrigerant: refrigerationFilter,
        systemType: systemTypeFilter,
        owner: ownerFilter,
        type: typeFilter,
        online: online,
        alarm: alarm,
        alarms: alarmRange
      }
    }
    this.router.navigate(['/search'], navigationExtras).then()
  }

  resetFilter() {
    this.selectedTypes.setValue(this.service.availableTypes)
    this.filter.get('refrigerationFilter')?.setValue('')
    this.filter.get('typeFilter')?.setValue('')
    this.filter.get('ownerFilter')?.setValue('')
    this.applyFilter()
  }

  applyFilter() {
    this.search(this.service.query)
  }


  private handleParamsChange(value: ParamMap) {
    let query = value.get('query') ?? ""
    let refrigerant = value.get('refrigerant') ?? ""
    let systemType = value.get('systemType') ?? ""
    let owner = value.get('owner') ?? ""
    let types = this.selectedTypes.value ?? []


    this.filter.get('refrigerationFilter')?.setValue(refrigerant)
    this.filter.get('systemTypeFilter')?.setValue(systemType)
    this.filter.get('ownerFilter')?.setValue(owner)
    this.selectedTypes.setValue(types)

    this.service.setFilter(query, refrigerant, systemType, owner, types)
  }

  back() {
    this.router.navigate(['/site']).then()
  }

  typeFilterChanged($event: MatChipListboxChange) {
    this.applyFilter()
  }
}

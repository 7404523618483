import { Component, Input } from '@angular/core';
import { ResourceHierarchyNode } from "../../hierarchy/model/hierarchy-api";

@Component({
    selector: 'app-search-result-entry-hierarchy',
    templateUrl: './search-result-entry-hierarchy.component.html',
    styleUrls: ['./search-result-entry-hierarchy.component.scss'],
    standalone: false
})
export class SearchResultEntryHierarchyComponent {
  @Input() data: ResourceHierarchyNode | undefined
}
